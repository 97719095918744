@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('react-phone-number-input/style.css');
.active {
  color: skyblue;
}

body {
  width: 100vw !important;
  overflow-x: hidden;
  background-color: white;
  scroll-behavior: smooth !important;
  -webkit-overflow-scrolling: touch;
}
.slide-in {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.toast-message {
  background: #1F2C5B;
  color: #fff;
  font-size: 16px;
  padding: 30px 20px;
}
.swiper-pagination {
  position: absolute;
  bottom: 0 !important;
  left: 50%;
  transform: translateX(-50%);
}
.styleswipper {
  .swiper-pagination-bullet {
    background-color: white;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    opacity: 0.5;
    border-radius: 50%;

    &-active {
      background-color: #1F2C5B;
      opacity: 1;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-top: -15px;
    border-radius: 50%;
    background-color: #1F2C5B !important;
    opacity: 0.5;
    z-index: 10;
    @media (max-width: 700px) {
      width: 35px;
      height: 35px;
    }

    &::after {
      color: white;
      position: absolute;
      width: 10px;
      height: 10px;
      top: 35%;
      left: 50%;
      font-size: 1.5rem;
      transform: translate(-50%, -50%);
      border-style: solid;
      border-color: #fff;
      z-index: 50;
    }
  }

  .swiper-button-next {
    right: 40px;
    @media (max-width: 700px) {
      right: 15px;
    }
  }

  .swiper-button-prev {
    left: 10px;

    &::after {
      left: 40%;
    }
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    opacity: 1;
  }
}
.ctabutton {
  opacity: 0.5; // semi-transparent when not in view
  transition: opacity 0.3s ease-out;

  &--in-view {
    opacity: 1; // fully opaque when in view
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.thin-scrollbar {
  /* Set the vertical scrollbar to appear only when needed */
  overflow-y: auto;
  ::-webkit-scrollbar-thumb {
    background-color: initial;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: initial; /* Keep the default color */
  }

  /* Firefox-specific styles for thin scrollbar */
  scrollbar-width: thin;
  scrollbar-color: auto;
}

input[type='checkbox']:checked {
  background-color: #1F2C5B;
  border: 1px solid #1F2C5B;
}
input[type='checkbox']:checked:after {
  content: '\2713';
  color: white;
  top: 50%; /* Set top as a percentage */
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
input[type='checkbox'] {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 16px !important;
  height: 16px !important;
  appearance: none;
  border-radius: 10%;
  border: 1px solid #757575;
  box-shadow: none;
  font-size: 1em;
  position: relative;
}
input[type='radio'] {
  display: inline-block;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #CCC;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  padding: 2px;
  cursor: pointer;
}

input[type='radio']:checked {
  border-color: #1F2C5B;
}

input[type='radio']:checked::after {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #1F2C5B;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid white;
}

.things-to-do-faq {
  a {
    color: #60a5fa;
  }
}

.leaflet-popup-close-button {
  display: none !important;
}
.leaflet-popup-content p {
  margin: 0px;
}
input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 3px;
  padding: 0;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  color: #FF4545;
  background: #e0e0e0;
}

/*Chrome thumb*/

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 5px;
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background: #1F2C5B;
  color: #1F2C5B;
}

/*Mozilla thumb*/

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  -moz-border-radius: 5px;
  height: 14px;
  width: 14px;
  border-radius: 5px;
  background: #1F2C5B;
  color: #1F2C5B;

  border: 1px solid #c5c5c5;
}

/*IE & Edge thumb*/

input[type='range']::-ms-thumb {
  height: 14px;
  width: 14px;
  border-radius: 50px;
  background: #1F2C5B;
  color: #1F2C5B;

  border: 1px solid #c5c5c5;
}

/*IE & Edge left side*/

input[type='range']::-ms-fill-lower {
  background: #1F2C5B;
  color: #1F2C5B;
  border-radius: 50px;
}

/*IE & Edge right side*/

input[type='range']::-ms-fill-upper {
  background: #1F2C5B;
  color: #1F2C5B;
  border-radius: 50px;
}

//For making rounded underline for active links of navbar
.underline-active {
  position: relative;
}

.underline-active::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px; /* Adjust the position of the underline */
  height: 4px; /* Adjust the thickness of the underline */
  background-color: #1F2C5B; /* Use your primary color */
  border-radius: 2px; /* Adjust the border radius for rounded corners */
}
